<template>
    <div class="attr-search-ab" v-if="scene == 1001">
        <div class="attr-search-ab-item">
            <span class="attr-search-ab-item-label">AB实验名称</span>
            <el-select v-model="searchSetting.abId" :options="abList" @change="onChangeAbId" size="small">
                <el-option v-for="item in abList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
        </div>
        <div class="attr-search-ab-item" v-if="searchSetting.abId">
            <span class="attr-search-ab-item-label">选中实验</span>
            <el-radio-group v-model="activeAb" @change="handleChange" size="mini">
                <el-radio-button v-for="(ele, index) in searchSetting.data" :key="ele.value" :label="ele.value">{{
                    ele.label }}</el-radio-button>
            </el-radio-group>
        </div>

        <div class="attr-search-ab-item">
            <span class="attr-search-ab-item-label">搜索样式</span>
            <el-radio-group v-model="searchType" @change="onChangeStyle" size="small">
                <el-radio v-for="item in options" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
            </el-radio-group>
        </div>
        <div class="attr-search-ab-item">
            <span class="attr-search-ab-item-label">颜色设置</span>
            <el-radio-group v-model="colorsetting" @change="onChangeColorsetting" size="mini">
                <el-radio-button key="white" label="white">浅色</el-radio-button>
                <el-radio-button key="black" label="black">深色</el-radio-button>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { pickBy } from "@/utils";
export default {
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        uuid: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            abList: [{ title: '无实验', value: '' }],
            activeAb: '',
            searchSetting: {
                abId: '',
                abName: '',
                data: [],
                currentTest: ''
            },
            searchType: false,
            options: [{
                title: '图标',
                value: 'icon'
            }, {
                title: '搜索框',
                value: 'searchInput'
            }],
            scene: '',
            colorsetting: 'white'
        }
    },
    mounted() {
        this.scene = this.$route.query.scene
        if (this.scene == 1001) {
            this.getAbList();
        } else {

        }
    },
    watch: {
        uuid: {
            handler() {
                this.searchSetting = cloneDeep(this.value)
                this.searchType = this.value.data[0].data
                this.colorsetting = this.value.data[0].colorsetting || 'white'
            },
            immediate: true
        },
        searchSetting: {
            handler(newVal) {
                console.log(newVal, 'newVal')
                this.$emit('input', newVal)
            },
            deep: true
        }
    },
    methods: {
        async getAbList() {
            const { experiments } = await this.$api.abtest.getExperimentList({ name: '' })
            this.abList = [{ title: '无实验', value: '' }, ...pickBy(experiments, { title: 'name', value: 'id' })]
        },
        async onChangeAbId(value) {
            this.$set(this.searchSetting, 'abId', value)
            this.$set(this.searchSetting, 'abName', '')
            this.$set(this.searchSetting, 'currentTest', '')
            this.activeAb = ''
            if (!value) {
                let data = [{ label: '', name: '', value: '', data: 'icon' }]
                this.$set(this.searchSetting, 'data', data)
                this.searchType = 'icon'
                this.colorsetting = 'white'
                return
            }
            const { experiment } = await this.$api.abtest.getExperimentDetail({ id: value })
            let options = pickBy(experiment.experiment_groups, {
                label: 'cname',
                value: 'group_variables[0].value',
                name: 'group_variables[0].name',
            })
            this.$set(this.searchSetting, 'abName', options[0]?.name)
            options = [{ label: '兜底组', value: '' }, ...options]
            const data = options.map(item => {
                return {
                    label: item.label,
                    value: item.value,
                    name: item.name,
                    data: 'icon',
                    colorsetting: 'white'
                }
            })
            this.$set(this.searchSetting, 'data', data)
            this.searchType = 'icon'
            this.colorsetting = 'white'
        },
        handleChange(e) {
            const info = this.searchSetting.data.find(item => item.value == e)
            this.$set(this.searchSetting, 'currentTest', e)
            console.log('info', info)
            this.searchType = info?.data
            this.colorsetting = info?.colorsetting||'white'
        },
        onChangeStyle(e) {
            const index = this.searchSetting.data.findIndex(item => item.value == this.activeAb)
            if (index > -1) {
                let info = cloneDeep(this.searchSetting.data[index]) || {}
                info.data = e
                this.$set(this.searchSetting.data, index, info)
            }
        },
        onChangeColorsetting(e) {
            const index = this.searchSetting.data.findIndex(item => item.value == this.activeAb)
            if (index > -1) {
                let info = cloneDeep(this.searchSetting.data[index]) || {}
                info.colorsetting = e
                this.$set(this.searchSetting.data, index, info)
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.attr-search-ab {
    margin-left: -90px;

    .attr-search-ab-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .attr-search-ab-item-label {
            display: inline-block;
            width: 90px;
            color: #888;
        }
    }
}
</style>
